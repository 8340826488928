import PlanificationCalendar from "../../widgets/planificationCalendar/planificationCalendar";
import { createContext, Dispatch, SetStateAction, useState } from "react";
import UploadArchive from "../../widgets/uploadArchive/uploadArchive";
import HeaderComponent from "../../widgets/header/header";
import "./planificactionView.scss";

export const PlanificationContext = createContext<{
    role: string;
    setRole: Dispatch<SetStateAction<string>>;
    availableNurses: any[];
    setAvailableNurses: Dispatch<any>;
}>({
    role: "",
    setRole: () => {},
    availableNurses: [],
    setAvailableNurses: () => {},
});

export default function PlanificationView() {
    const [role, setRole] = useState("");
    const [availableNurses, setAvailableNurses] = useState([]);

    return (
        <PlanificationContext.Provider
            value={{
                role,
                setRole,
                availableNurses,
                setAvailableNurses,
            }}
        >
            <div className="GeneralContainer" style={{ height: "100vh" }}>
                <HeaderComponent showSearch={false}></HeaderComponent>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div id="subtitle">Planificación de asistencia</div>
                    <a className="buttonActionOptions" href="https://drive.google.com/uc?export=download&id=1Zf06lH_n6NU_4K-bANjtLC26AfVrsdeJ">
                        Descargar plantilla automatizada
                    </a>
                </div>
                <PlanificationCalendar></PlanificationCalendar>;
            </div>
        </PlanificationContext.Provider>
    );
}
